import { useState, useEffect } from "react";

const useScrollPosition = () => {
  if (typeof window === "undefined") {
    return { x: 0, y: 0 };
  }
  const [windowScroll, setWindowScroll] = useState({
    x: window.scrollX,
    y: window.scrollY,
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });
  const scrollHandler = () => {
    setWindowScroll({
      x: window.scrollX,
      y: window.scrollY,
    });
  };
  return windowScroll;
};

export default useScrollPosition;
