import React from "react";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { Global, css, ThemeProvider } from "@emotion/react";

import rosen from "../theme/rosen";
import { Theme } from "@emotion/react";

const globalStyles = (props: Theme) => css`
  body {
    margin: 0;
    font-family: "Zen Kaku Gothic New", sans-serif;
    color: ${props.main.text};
    background-color: ${props.main.background};
    transition: background-color 1s;

    * {
      box-sizing: border-box;
    }
  }

  h1 {
    font-size: 3rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:visited,
    &:active,
    &:hover {
      color: inherit;
    }
  }

  pre {
    background-color: ${props.code.codeBackground};
    code {
      font-family: "Jet Brains Mono", monospace;
      font-size: 0.8rem;

      // Level 1
      & .token.annotation {
        color: ${props.code.codeAnnotation};
      }
      & .token.atrule {
        color: ${props.code.codeAtRule};
      }
      & .token.attr-name {
        color: ${props.code.codeAttrName};
      }
      & .token.attr-value {
        .attr-equals {
          color: ${props.code.codeDefault};
        }
        color: ${props.code.codeAttrValue};
      }
      & .token.bold {
        color: ${props.code.codeBold};
        font-weight: bold;
      }
      & .token.boolean {
        color: ${props.code.codeBoolean};
      }
      & .token.cdata {
        color: ${props.code.codeCData};
      }
      & .token.char {
        color: ${props.code.codeChar};
      }
      & .token.class-name {
        color: ${props.code.codeClass};
      }
      & .token.comment {
        color: ${props.code.codeComment};
        font-style: italic;
      }
      & .token.constant {
        color: ${props.code.codeConstant};
      }
      & .token.deleted {
        color: ${props.code.codeDeleted};
      }
      & .token.delimiter {
        color: ${props.code.codeDelimiter};
      }
      & .token.doctype {
        color: ${props.code.codeDoctype};
        font-style: italic;
      }
      & .token.entity {
        color: ${props.code.codeEntity};
      }
      & .token.function {
        color: ${props.code.codeFunction};
      }
      & .token.important {
        color: ${props.code.codeImportant};
        font-weight: bold;
      }
      & .token.inserted {
        color: ${props.code.codeInserted};
      }
      & .token.interpolation > .punctuation {
        &:first-child,
        &:last-child {
          color: ${props.code.codeInterpolation};
        }
      }
      & .token.italic {
        color: ${props.code.codeItalic};
        font-style: italic;
      }
      & .token.keyword {
        color: ${props.code.codeKeyword};
        font-weight: bold;
      }
      & .token.number {
        color: ${props.code.codeNumber};
      }
      & .token.operator {
        color: ${props.code.codeOperator};
      }
      & .token.parameter {
        color: ${props.code.codeParameter};
      }
      & .token.prolog {
        color: ${props.code.codeProlog};
      }
      & .token.property {
        color: ${props.code.codeProperty};
      }
      & .token.punctuation {
        color: ${props.code.codePunctuation};
      }
      & .token.regex * {
        color: ${props.code.codeRegex};
      }
      & .token.selector {
        color: ${props.code.codeSelector};
      }
      & .token.string {
        color: ${props.code.codeString};
      }
      & .token.symbol {
        color: ${props.code.codeSymbol};
      }
      & .token.tag {
        color: ${props.code.codeTag};
        .punctuation {
          color: ${props.code.codeDefault};
        }
      }
      & .token.url {
        color: ${props.code.codeUrl};
      }
      & .token.variable {
        color: ${props.code.codeVariable};
      }

      // Level 2
      & .token.builtin {
        color: ${props.code.codeBuiltin};
      }
      & .token.shebang {
        color: ${props.code.codeDoctype};
        font-weight: normal;
        font-style: italic;
      }
      & .token.directive {
        color: ${props.code.codeDoctype};
        font-weight: normal;
        font-style: italic;
      }
    }
  }
`;

const client = new QueryClient();

export default function Root(props: { children: JSX.Element }) {
  return (
    <>
      {/* Providers & Main Content */}
      <RecoilRoot>
        <QueryClientProvider client={client}>
          <ThemeProvider theme={rosen}>
            <Global styles={globalStyles} />
            {props.children}
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}
