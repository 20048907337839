// Default light theme

import { Theme } from "@emotion/react";

const theme: Theme = {
  isLight: true,
  main: {
    logo: "#0099cc",
    toMain: "#ff9933",
    text: "#111111",
    background: "#ffffff",
    gradient1: "#006699",
    gradient2: "#0099cc",
    nav: "#0099cc",
  },
  list: {
    border: "#00000000",
    shadow: "#7f7f7f",
    langEn: "#006699",
    langJa: "#ff3333",
    project: "#006699",
    article: "#ff3333",
    search: "#7f7f7f",
  },
  article: {
    heading: "#006699",
    emphasis: "#003311",
    caption: "#333333",
    quoteBorder: "#9999997F",
    quoteIcon: "#333333",
    admInfoBase: "#cceeff",
    admInfoText: "#003366",
    admSuccessBase: "#ddffcc",
    admSuccessText: "#223311",
    admWarningBase: "#ffcc00",
    admWarningText: "#443311",
    admDangerBase: "#ff6666",
    admDangerText: "#331111",
  },
  code: {
    codeBackground: "#fdfdfd",
    codeDefault: "#111111",
    codeSelection: "",
    codeSelectionBackground: "",
    codeAnnotation: "#ff3333",
    codeAtRule: "#0099cc",
    codeAttrName: "#cc9933",
    codeAttrValue: "#339933",
    codeBold: "",
    codeBoolean: "#006699",
    codeBuiltin: "#0099cc",
    codeCData: "",
    codeChar: "#339933",
    codeClass: "#006699",
    codeComment: "#666666",
    codeConstant: "",
    codeDeleted: "",
    codeDelimiter: "",
    codeDoctype: "#666666",
    codeEntity: "",
    codeFunction: "#0099cc",
    codeImportant: "#ff3333",
    codeInserted: "",
    codeInterpolation: "#ff6633",
    codeItalic: "",
    codeKeyword: "#ff6633",
    codeNumber: "#6633ff",
    codeOperator: "",
    codeParameter: "",
    codeProlog: "",
    codeProperty: "#cc9933",
    codePunctuation: "",
    codeRegex: "#ff3333",
    codeSelector: "#ff6633",
    codeString: "#339933",
    codeSymbol: "",
    codeTag: "#ff6633",
    codeUrl: "",
    codeVariable: "",
  },
};

export default theme;
