exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-sanity-article-article-id-current-tsx": () => import("./../../../src/pages/articles/{SanityArticle.article_id__current}.tsx" /* webpackChunkName: "component---src-pages-articles-sanity-article-article-id-current-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-sanity-project-project-id-current-tsx": () => import("./../../../src/pages/projects/{SanityProject.project_id__current}.tsx" /* webpackChunkName: "component---src-pages-projects-sanity-project-project-id-current-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-pages-tags-sanity-tag-tag-id-current-tsx": () => import("./../../../src/pages/tags/{SanityTag.tag_id__current}.tsx" /* webpackChunkName: "component---src-pages-tags-sanity-tag-tag-id-current-tsx" */)
}

