const breakpoints = {
  sm: 500,
  md: 800,
  lg: 1200,
  xl: 1400,
};

export default {
  values: breakpoints,
  media: {
    sm: `@media screen and (max-width: ${breakpoints.sm}px)`,
    md: `@media screen and (max-width: ${breakpoints.md}px)`,
    lg: `@media screen and (max-width: ${breakpoints.lg}px)`,
    xl: `@media screen and (max-width: ${breakpoints.xl}px)`,
  },
};
