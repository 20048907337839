import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import breakpoints from "../utils/breakpoints";
import useScrollPosition from "../utils/useScrollPosition";
import backgroundColour from "../atoms/backgroundColour";

const LayoutStyles = styled.div`
  transition: all 1s;

  nav {
    position: fixed;
    top: 0;
    height: 100vh;
    padding-right: 16px;
    z-index: 100;

    display: flex;

    opacity: 0.1;

    .handle {
      display: flex;
      margin: auto 0;
      flex-direction: column;
      height: min-content;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      pointer-events: none;

      a {
        color: white;
      }
      background-color: ${(props) => props.theme.main?.nav};

      svg {
        margin: 24px;
        width: 32px;
        height: 32px;
      }
    }

    // Pull-out
    transition: all 200ms;

    &:focus-within,
    &.expanded {
      transform: translate(0);
      opacity: 1;

      .handle {
        pointer-events: all;
      }
    }

    ${breakpoints.media.xl} {
      transform: translate(-56px);
    }
  }

  main {
    height: 100%;
    padding: 32px;
  }
`;

function Layout(props: React.HTMLProps<HTMLDivElement>) {
  const [showNav, setShowNav] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [bgColourValue] = useRecoilState(backgroundColour);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  });

  const { y } = useScrollPosition();
  useEffect(() => {
    if (document.activeElement?.parentElement?.className === "handle") {
      document.activeElement?.blur();
      setExpanded(false);
    }
  }, [y]);

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${bgColourValue};
          }
        `}
      />
      <LayoutStyles id="page">
        {showNav && (
          <nav
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
            onTouchEndCapture={(e) => {
              console.log(e);
              if (!expanded) {
                setExpanded(true);
                e.preventDefault();
              }
            }}
            className={expanded ? "expanded" : ""}
          >
            <div className="handle">
              <AniLink fade to="/">
                <svg viewBox="0 0 75 100">
                  <path fill="currentColor" d="M25 0a25 25 0 0125 25v1H25" />
                  <path fill="currentColor" d="M25 25h25v25H25" />
                  <path fill="currentColor" d="M25 25A25 25 0 000 50h26V25" />
                  <path fill="currentColor" d="M50 50a25 25 0 0025-25H49v25" />
                  <path fill="currentColor" d="M25 49h25v26H25" />
                  <path fill="currentColor" d="M25 75a25 25 0 0025 25V74H25" />
                  <path fill="currentColor" d="M50 100a25 25 0 0025-25H49v25" />
                </svg>
              </AniLink>
              <AniLink fade to="/projects">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M21 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H21C22.1 22 23 21.1 23 20V4C23 2.9 22.1 2 21 2M11 11H9V12.5C9 13.6 8.1 14.5 7 14.5C8.1 14.5 9 15.4 9 16.5V18H11V20H9C7.9 20 7 19.1 7 18V17.5C7 16.4 6.1 15.5 5 15.5V13.5C6.1 13.5 7 12.6 7 11.5V11C7 9.9 7.9 9 9 9H11V11M19 15.5C17.9 15.5 17 16.4 17 17.5V18C17 19.1 16.1 20 15 20H13V18H15V16.5C15 15.4 15.9 14.5 17 14.5C15.9 14.5 15 13.6 15 12.5V11H13V9H15C16.1 9 17 9.9 17 11V11.5C17 12.6 17.9 13.5 19 13.5V15.5M21 7H3V4H21V7Z"
                  />
                </svg>
              </AniLink>
              <AniLink fade to="/articles">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M17.5 14.33C18.29 14.33 19.13 14.41 20 14.57V16.07C19.38 15.91 18.54 15.83 17.5 15.83C15.6 15.83 14.11 16.16 13 16.82V15.13C14.17 14.6 15.67 14.33 17.5 14.33M13 12.46C14.29 11.93 15.79 11.67 17.5 11.67C18.29 11.67 19.13 11.74 20 11.9V13.4C19.38 13.24 18.54 13.16 17.5 13.16C15.6 13.16 14.11 13.5 13 14.15M17.5 10.5C15.6 10.5 14.11 10.82 13 11.5V9.84C14.23 9.28 15.73 9 17.5 9C18.29 9 19.13 9.08 20 9.23V10.78C19.26 10.59 18.41 10.5 17.5 10.5M21 18.5V7C19.96 6.67 18.79 6.5 17.5 6.5C15.45 6.5 13.62 7 12 8V19.5C13.62 18.5 15.45 18 17.5 18C18.69 18 19.86 18.16 21 18.5M17.5 4.5C19.85 4.5 21.69 5 23 6V20.56C23 20.68 22.95 20.8 22.84 20.91C22.73 21 22.61 21.08 22.5 21.08C22.39 21.08 22.31 21.06 22.25 21.03C20.97 20.34 19.38 20 17.5 20C15.45 20 13.62 20.5 12 21.5C10.66 20.5 8.83 20 6.5 20C4.84 20 3.25 20.36 1.75 21.07C1.72 21.08 1.68 21.08 1.63 21.1C1.59 21.11 1.55 21.12 1.5 21.12C1.39 21.12 1.27 21.08 1.16 21C1.05 20.89 1 20.78 1 20.65V6C2.34 5 4.18 4.5 6.5 4.5C8.83 4.5 10.66 5 12 6C13.34 5 15.17 4.5 17.5 4.5Z"
                  />
                </svg>
              </AniLink>
              <AniLink fade to="/tags">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
                  />
                </svg>
              </AniLink>
              <AniLink fade to="/about">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M1.5,4V5.5C1.5,9.65 3.71,13.28 7,15.3V20H22V18C22,15.34 16.67,14 14,14C14,14 13.83,14 13.75,14C9,14 5,10 5,5.5V4M14,4A4,4 0 0,0 10,8A4,4 0 0,0 14,12A4,4 0 0,0 18,8A4,4 0 0,0 14,4Z"
                  />
                </svg>
              </AniLink>
            </div>
          </nav>
        )}
        <main onTouchEnd={() => setExpanded(false)}>{props.children}</main>
      </LayoutStyles>
    </>
  );
}

export default Layout;
